const form = {
  formId: "new-user-form",
  formField: {
    ecommText: {
      name: "ecommText",
      label: "Ecomm Text",
      type: "text",
      errorMsg: "Ecomm Text is required.",
    },
    category: {
      name: "category",
      label: "Category",
    },
  },
};

export default form;
