/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/
import { createContext, useContext, useReducer, useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React main context
const MaterialUI = createContext();

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";

const baseURL = process.env.REACT_APP_baseURL;

// Material Dashboard 2 React reducer
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "UPDATE_MARKET_TAGS_FOR_BUSINESS": {
      return { ...state, updateMarketTagsForBusiness: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    case "MARKET_MESSAGE": {
      if (action.value.open) {
        return {
          ...state,
          openMarketMessage: action.value.open,
          openMarketMessageSettings: {
            color: action.value.color || "success",
            icon: action.value.icon || "check",
            title: action.value.title || "Success",
            content: action.value.content,
          },
        };
      }
      return {
        ...state,
        openMarketMessage: action.value.open,
      };
    }
    case "MARKET_MESSAGE_ERROR": {
      if (action.value.open) {
        return {
          ...state,
          openMarketMessage: action.value.open,
          openMarketMessageSettings: {
            color: action.value.color || "success",
            icon: action.value.icon || "check",
            title: action.value.title || "Success",
            content: action.value.content,
          },
        };
      }
      return {
        ...state,
        openMarketMessage: action.value.open,
      };
    }
    case "SET_REGION": {
      return { ...state, regions: action.value };
    }
    case "SET_CONTEXTSUGGESTIONS": {
      return { ...state, contextSuggestions: action.value };
    }
    case "RESET": {
      return { ...state, isLoading: false, isSuccess: false, isError: false, message: "" };
    }
    case "REGISTER": {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isError: false,
        userData: action.value,
      };
    }
    case "REGISTER_FAILED": {
      return { ...state, isLoading: false, isSuccess: false, isError: true, message: action.value };
    }
    case "LOGIN": {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isError: false,
        userData: action.value,
      };
    }
    case "LOGIN_FAILED": {
      return { ...state, isLoading: false, isSuccess: false, isError: true, message: action.value };
    }
    case "LOGOUT": {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: false,
        userData: null,
        regions: [],
        contextSuggestions: [],
      };
    }
    case "ADDUSER": {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isError: false,
      };
    }
    case "ADDUSER_FAILED": {
      return { ...state, isLoading: false, isSuccess: false, isError: true, message: action.value };
    }
    case "LOAD_MARKET_MANAGEMENT": {
      return { ...state, loadMarketManagement: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Material Dashboard 2 React context provider
function MaterialUIControllerProvider({ children }) {
  const userDataLocalStorage = JSON.parse(localStorage.getItem("userData"));
  const initialState = {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    updateMarketTagsForBusiness: false,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    darkMode: false,
    loadMarketManagement: 0,
    openMarketMessage: false,
    openMarketMessageSettings: {
      color: "success", // "success",
      icon: "", // "check",
      title: "", // "Data Saved",
      content: "", // "Data has been saved successfully",
    },
    regions: [],
    contextSuggestions: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    userData: userDataLocalStorage || null,
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// Material Dashboard 2 React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setUpdateMarketTagsForBusiness = (dispatch, value) =>
  dispatch({ type: "UPDATE_MARKET_TAGS_FOR_BUSINESS", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });
const setLoadMarketManagement = (dispatch, value) =>
  dispatch({ type: "LOAD_MARKET_MANAGEMENT", value });
const setOpenMarketMessage = (dispatch, value) => dispatch({ type: "MARKET_MESSAGE", value });
const setOpenMessageError = (dispatch, value) => dispatch({ type: "MARKET_MESSAGE_ERROR", value });

const getContextSuggestions = async (dispatch) => {
  const userDataLocalStorage = JSON.parse(localStorage.getItem("userData"));
  if (userDataLocalStorage && userDataLocalStorage.token) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userDataLocalStorage.token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(`${baseURL}/markets/marketsuggestions`, requestOptions);
    const responseJson = await response.json();

    // const newValue = `${value} desde el dispatch ${responseJson.length}`;
    return dispatch({ type: "SET_CONTEXTSUGGESTIONS", value: responseJson });
  }
  return "Error at getContextSuggestions";
};

const register = async (dispatch, userData) => {
  const { email, password, role, name } = userData;

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  const urlencoded = new URLSearchParams();
  urlencoded.append("username", email);
  urlencoded.append("password", password);
  urlencoded.append("role", role);
  urlencoded.append("name", name);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  let response;
  let responseJson;
  try {
    response = await fetch(`${baseURL}/users/signup`, requestOptions);
    responseJson = await response.json();
    localStorage.setItem("userData", JSON.stringify(responseJson));
    return dispatch({ type: "REGISTER", value: responseJson });
  } catch (err) {
    // console.log(err);
    return dispatch({ type: "REGISTER_FAILED", value: err });
  }
};

const login = async (dispatch, userData) => {
  const { email, password } = userData;

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  const urlencoded = new URLSearchParams();
  urlencoded.append("login", email);
  urlencoded.append("password", password);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  let response;
  let responseJson;
  try {
    response = await fetch(`${baseURL}/users/login`, requestOptions);
    responseJson = await response.json();
    localStorage.setItem("userData", JSON.stringify(responseJson));
    return dispatch({ type: "LOGIN", value: responseJson });
  } catch (err) {
    // console.log(err);
    return dispatch({ type: "LOGIN_FAILED", value: err });
  }
};

const reset = async (dispatch) => dispatch({ type: "RESET" });

const logout = async (dispatch) => {
  localStorage.removeItem("userData");
  dispatch({ type: "LOGOUT" });
};

const adduser = async (dispatch, userData) => {
  const { email, password, role, name } = userData;

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  const urlencoded = new URLSearchParams();
  urlencoded.append("username", email);
  urlencoded.append("password", password);
  urlencoded.append("role", role);
  urlencoded.append("name", name);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  let response;
  let responseJson;
  try {
    response = await fetch(`${baseURL}/users/signup`, requestOptions);
    responseJson = await response.json();
    return dispatch({ type: "ADDUSER", value: responseJson });
  } catch (err) {
    // console.log(err);
    return dispatch({ type: "ADDUSER_FAILED", value: err });
  }
};

const validateRole = ({ role, pathname }) => {
  switch (role) {
    case "cs":
      if (
        [
          "/deactivate-markets",
          "/manage-tags",
          "/view-all-tags",
          "/view-all-tagsII",
          "/find-trading-volume",
          "/adduser",
          "/snapshots-overview", // PRODUCT USAGE
          "/subscription-overview", // PRODUCT USAGE
          "/analysis", // PRODUCT USAGE
          "/monthly-analysis", // PRODUCT USAGE
          "/markets", // MARKETS
          "/gmw-comments-summary", // MARKETS
          "/missing-models", // MISSING MODELS ANALYSIS
          "/missing-market-analysis", // MARKETS
          "/failed-markets/timeframe/daily", // FAILED MARKETS
          "/processes", // DATA PROCESSES
          "/data-download-check", // DATA DOWNLOAD CHECK SUBMENU
          "/market-data-check", // MARKET DATA CHECK SUBMENU
          "/markets-management/market-talk-text", // MARKET TALK TEXT
        ].includes(pathname)
      )
        return { access: false, goBack: "/dashboard" };
      return { access: true };
    case "marketing":
      if (
        [
          "/adduser",
          "/dashboard",
          "/markets", // MARKETS
          "/gmw-comments-summary", // MARKETS
          "/missing-models", // MISSING MODELS ANALYSIS
          "/missing-market-analysis", // MARKETS
          "/failed-markets/timeframe/daily", // FAILED MARKETS
          "/processes", // DATA PROCESSES
        ].includes(pathname)
      )
        return { access: false, goBack: "/market-subscription-summary" };
      return { access: true };
    default:
      return { access: true };
  }
};

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
  setLoadMarketManagement,
  setOpenMarketMessage,
  setOpenMessageError,
  getContextSuggestions,
  register,
  reset,
  login,
  logout,
  adduser,
  validateRole,
  setUpdateMarketTagsForBusiness,
};
