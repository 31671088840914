// react
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMaterialUIController, validateRole } from "context";
import { useQueries } from "@tanstack/react-query";

import http from "services/http";

import MDLoader from "components/MDLoader";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTableRowSelectMarketTalkText from "examples/Tables/DataTableRowSelectMarketTalkText";

// Data
import marketsTalkText from "layouts/tables/data/marketsTalkText";

function MarketTalkText() {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { userData } = controller;
  const { pathname } = useLocation();

  const handleNewMarketRequest = async (e) => {
    e.preventDefault();
    navigate("/markets-management/market-talk-text/new-market-talk-text");
  };

  const { columns1 } = marketsTalkText();

  const getMarketTalkText = async () => {
    const result = await http({
      path: `/markettalktext`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const [marketTalkText] = useQueries({
    queries: [
      {
        queryKey: ["/markets-management/market-talk-text"],
        queryFn: getMarketTalkText,
      },
    ],
  });

  useEffect(() => {
    if (userData !== null) {
      const { role } = userData;
      const { access, goBack } = validateRole({ role, pathname });
      if (!access) navigate(goBack);
    }
  }, [userData]);

  // const approveMarketsByTradingVolume = async (itemsToApprove) => {
  //   const results = [];

  //   const fullInformationArray = findFullInformation(itemsToApprove, marketInfoRequest.data);

  //   const filteredArray = fullInformationArray.filter(
  //     // (obj) => obj.Status === "Approved" || obj.Status === "Pending for Approval"
  //     (obj) => obj.StatusId === 3 || obj.StatusId === 2
  //   );
  //   const ids = filteredArray.map((el) => el.item);

  //   await Promise.all(
  //     ids.map(async (element) => {
  //       // const { StatusId: elementStatusId } = marketInfoRequest.data.filter(
  //       //   // eslint-disable-next-line no-underscore-dangle
  //       //   (el) => el._id === element
  //       // )[0];

  //       // let nextStatus;
  //       // switch (elementStatusId) {
  //       //   // case "Pending for Approval":
  //       //   case 1:
  //       //     nextStatus = { ApprovalDate: new Date(), StatusId: 2 };
  //       //     break;
  //       //   // case "Approved":
  //       //   case 2:
  //       //     nextStatus = { CompletionDate: new Date(), StatusId: 3 };
  //       //     break;
  //       //   default:
  //       //     break;
  //       // }

  //       const result = await updateManyMarketInfoRequest({
  //         id: element,
  //         IsTradingVolumeEnough: true,
  //         // ...nextStatus,
  //       });
  //       results.push(result);
  //     })
  //   );
  //   const allResultsSuccessful = results.every((result) => result.ok);

  //   if (allResultsSuccessful) {
  //     await setOpenMarketMessage(dispatch, {
  //       open: true,
  //       color: "success",
  //       icon: "check",
  //       title: "Success",
  //       content: "Request has been updated",
  //     });
  //   } else {
  //     await setOpenMarketMessage(dispatch, {
  //       open: true,
  //       color: "error",
  //       icon: "error",
  //       title: "Database Error",
  //       content: "There was an error updating the information",
  //     });
  //   }
  //   marketInfoRequest.refetch();
  //   // just status is gonna be updated
  //   // const mergedArray = marketInfoRequest.map((item1) => {
  //   //   // eslint-disable-next-line no-underscore-dangle
  //   //   const item2 = itemsToApprove.find((item) => item === item1._id);
  //   //   if (item2) {
  //   //     return { ...item1, Status: "Approved" };
  //   //   }
  //   //   return { ...item1 };
  //   // });

  //   // setMarketInfoRequest(mergedArray);
  // };

  if (marketTalkText.isLoading) return <MDLoader />;
  if (marketTalkText.isError) return <div>Error at loading market requests</div>;

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <DataTableRowSelectMarketTalkText
        table={{ columns: columns1, rows: marketTalkText.data || [] }}
        isSorted
        entriesPerPage
        showTotalEntries
        noEndBorder
        canSearch
        canApprove={false}
        approveMarkets={() => {}}
        canReject={false}
        rejectMarkets={() => {}}
        // approveMarketsByTradingVolume={approveMarketsByTradingVolume}
        approveMarketsByTradingVolume={() => {}}
        handleNewMarketRequest={handleNewMarketRequest}
      />

      <Footer />
    </DashboardLayout>
  );
}

export default MarketTalkText;
