// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// NewMarketRequest page components
// import FormField from "components/FormField";
// import { Checkbox } from "@mui/material";
import MDTypography from "components/MDTypography";
// import MDEditor from "components/MDEditor";
import { formatDateMDY } from "utils/dateUtil";
import BlogCardsII from "examples/Cards/BlogCardsII";

// Images
import booking1 from "assets/images/finance/image.png";

function UserInfo({ formData }) {
  const { row } = formData;

  // const toolbarOptions = [["bold", "italic"], ["link"], ["image"]];
  // const module = {
  //   // toolbar: [], // toolbarOptions,
  //   toolbar: false,
  // };

  return (
    <MDBox>
      <MDBox py={3}>
        <MDBox mt={0}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <MDBox>
                <MDTypography variant="body2" color="text" mb={6}>
                  Preview
                </MDTypography>
                <BlogCardsII
                  image={booking1}
                  title={row.Title}
                  description={row.EcommText}
                  price={formatDateMDY(row.Date).toString()}
                  location={row.Region}
                  action={null}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox mt={1.625}>
        {/* <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <FormField
              type="text"
              label="Date"
              name="Date"
              value={formatDateMDY(row.Date)}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField type="text" label="Region" name="Region" value={row.Region} disabled />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Checkbox name="active" label="active" checked={row.Active} disabled />
            <MDTypography variant="body2" color="text">
              {row.Active ? "Active" : "Inactive"}
            </MDTypography>
          </Grid>
        </Grid> */}
        {/* <Grid container spacing={3}>
          <Grid item xs={12} sm={12} mt={3}>
            <MDTypography variant="body2" color="text">
              Preview
            </MDTypography>
            <MDEditor modules={module} value={row.EcommText} readOnly />
          </Grid>
        </Grid> */}
      </MDBox>
    </MDBox>
  );
}

// typechecking props for UserInfo
UserInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default UserInfo;
