// react
// import { useMaterialUIController } from "context";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// NewMarketRequest page components
// import FormField from "components/FormField";
import MDTypography from "components/MDTypography";
import MDDatePickerMDY from "components/MDDatePickerMDY";
import MDEditor from "components/MDEditor";

function UserInfo({ formData }) {
  // const [controller] = useMaterialUIController();
  // const { userData } = controller;
  // const { name } = userData; // name, role, userId, username

  // const { formField, values, errors, touched, setFieldValue } = formData;
  const { formField, values, setFieldValue } = formData;
  // eslint-disable-next-line no-unused-vars
  const { ecommText, region, date, title } = formField;
  // eslint-disable-next-line no-unused-vars
  const { ecommText: ecommTextV, region: regionV, date: dateV, title: titleV } = values;

  return (
    <MDBox>
      <MDTypography variant="h5">Market Talk Text Information</MDTypography>
      <MDBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <MDBox mb={2}>
              <MDBox mb={1} display="inline-block">
                <MDTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                  Date
                </MDTypography>
              </MDBox>
              <Grid item xs={12} sm={12}>
                <MDDatePickerMDY
                  input={{ placeholder: "Select a date" }}
                  name={date}
                  value={dateV || null}
                  onChange={(e, value) => {
                    // console.log(value);
                    setFieldValue("date", value !== null ? value : "");
                  }}
                />
              </Grid>
            </MDBox>
            <MDBox mb={2}>
              <MDBox mb={1} display="inline-block">
                <MDTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                  Region
                </MDTypography>
              </MDBox>
              <Autocomplete
                id="region"
                name={region}
                options={["Asia", "Europe", "EMEA", "US/AMERICAS"]}
                value={regionV || null}
                getOptionLabel={(option) => option}
                style={{ width: 300 }}
                onChange={(e, value) => {
                  // console.log(value);
                  setFieldValue("region", value !== null ? value : "");
                }}
                renderInput={(params) => (
                  <MDInput {...params} variant="standard" /* label="Region" */ />
                )}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDBox mb={1} display="inline-block">
                <MDTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                  Title
                </MDTypography>
              </MDBox>
              <MDInput
                type="text"
                // label="Title"
                name="title"
                value={titleV}
                placeholder="Enter a title"
                onChange={(e) => {
                  setFieldValue("title", e.target.value);
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={12}>
            <MDEditor
              value={ecommTextV}
              onChange={(e) => {
                setFieldValue("ecommText", e);
              }}
            />
            {/* <FormField
              type={ecommText.type}
              label={ecommText.label}
              name={ecommText.name}
              value={ecommTextV}
              placeholder={ecommText.placeholder}
              error={errors.ecommText && touched.ecommText}
              success={ecommTextV.length > 0 && !errors.ecommText}
              multiline
              rows={3}
            /> */}
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for UserInfo
UserInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default UserInfo;
