/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

import { Icon } from "@mui/material";
import MDTypography from "components/MDTypography";
import {
  DateRangeColumnFilter,
  dateBetweenFilterFn,
} from "components/TableFilters/selectByFieldStatusId";
import { Link } from "react-router-dom";
// Material Dashboard 2 React components
import { formatDateInNewYorkNoSec } from "utils/dateUtil";

export default function data() {
  return {
    columns1: [
      {
        Header: "Date",
        accessor: (d) => formatDateInNewYorkNoSec(d.Date),
        align: "left",
        maxWidth: 115,
        minWidth: 115,
        Filter: DateRangeColumnFilter,
        filter: dateBetweenFilterFn,
      },
      {
        Header: "Title",
        accessor: "Title",
        align: "left",
        maxWidth: 220,
        minWidth: 220,
        filter: "fuzzyText",
        // Cell: ({ row }) => `${row.original.EcommText.substring(0, 15)}...`,
      },
      {
        Header: "Ecomm Text",
        accessor: "EcommText",
        align: "left",
        maxWidth: 220,
        minWidth: 220,
        filter: "fuzzyText",
        Cell: ({ row }) => `${row.original.EcommText.substring(0, 15)}...`,
      },
      {
        Header: "Active",
        accessor: (d) => (d.Active ? "Active" : "Inactive"),
        align: "left",
        maxWidth: 115,
        minWidth: 115,
        Filter: "",
      },
      {
        Header: "Region",
        accessor: "Region",
        align: "left",
        maxWidth: 220,
        minWidth: 220,
        filter: "fuzzyText",
      },
      {
        Header: "PostedBy",
        accessor: "PostedBy",
        align: "left",
        maxWidth: 220,
        minWidth: 220,
        filter: "fuzzyText",
      },
      {
        Header: "PostedByDate",
        accessor: (d) => formatDateInNewYorkNoSec(d.PostedByDate),
        align: "left",
        maxWidth: 115,
        minWidth: 115,
        Filter: DateRangeColumnFilter,
        filter: dateBetweenFilterFn,
      },
      {
        Header: "",
        accessor: "Action",
        align: "left",
        maxWidth: 140,
        minWidth: 140,
        Cell: ({ row }) => (
          <MDTypography variant="h4" color="text">
            <Link
              to="/markets-management/market-talk-text/view-market-talk-text"
              state={{ row: row.original }}
            >
              <Icon>visibility</Icon>
            </Link>
            <Link
              to="/markets-management/market-talk-text/edit-market-talk-text"
              state={{ row: row.original }}
            >
              <Icon>edit</Icon>
            </Link>
          </MDTypography>
        ),
        Filter: "",
      },
    ],
  };
}
