import checkout from "layouts/newmarkettalktext/schemas/form";

const {
  formField: { ecommText },
} = checkout;

const initialValues = {
  [ecommText.name]: "",
};

export default initialValues;
