import { useState } from "react";
import { useMaterialUIController, setOpenMarketMessage } from "context";
import { useQueryClient } from "@tanstack/react-query";

import httpPost from "services/httpPost";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import { Grid, Card } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewMarketRequest page components
import UserInfo from "layouts/newmarkettalktext/components/UserInfo";

// newmarkettalktext layout schemas for form and form feilds
import validations from "layouts/newmarkettalktext/schemas/validations";
import form from "layouts/newmarkettalktext/schemas/form";
import initialValues from "layouts/newmarkettalktext/schemas/initialValues";
import { useNavigate } from "react-router-dom";

function getStepContent(formData) {
  return <UserInfo formData={formData} />;
}

function NewMarketTalkText() {
  const [controller, dispatch] = useMaterialUIController();
  const { userData } = controller;
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const queryClient = useQueryClient();

  const { formId, formField } = form;
  const currentValidation = validations[activeStep];

  const postMarketTalkText = async (values) => {
    const result = await httpPost({
      path: `/markettalktext`,
      method: "POST",
      raw: JSON.stringify(values),
      accessToken: userData.token,
    });
    return result;
  };

  const submitForm = async (values, actions) => {
    const result = await postMarketTalkText({
      ...values,
      postedBy: userData.name,
      postedByEmail: userData.username,
      active: true,
      postedDate: new Date(),
    });
    if (result.ok && result.body) {
      queryClient.invalidateQueries({ queryKey: ["/markets-management/market-talk-text"] });
      await setOpenMarketMessage(dispatch, {
        open: true,
        content: "Document has been submitted",
      });
    } else {
      await setOpenMarketMessage(dispatch, {
        open: true,
        color: "error",
        icon: "error",
        title: "Database Error",
        content: result.message,
      });
    }

    actions.setSubmitting(false);
    actions.resetForm();
    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
    navigate("/markets-management/market-talk-text");
  };

  const handleCancel = (e) => {
    e.preventDefault();
    navigate("/markets-management/market-talk-text");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={0} mb={20} height="65vh">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 6 }}>
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h6" color="white">
                        New Market Talk Text
                      </MDTypography>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent({
                          values,
                          touched,
                          formField,
                          errors,
                          setFieldValue,
                        })}
                        <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          <MDButton
                            disabled={isSubmitting}
                            type="button"
                            variant="gradient"
                            color="error"
                            sx={{ marginLeft: "40px" }}
                            onClick={handleCancel}
                          >
                            cancel
                          </MDButton>
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="info"
                            sx={{ marginLeft: "40px", marginRight: "40px" }}
                          >
                            submit
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewMarketTalkText;
