import * as Yup from "yup";
import checkout from "layouts/newmarkettalktext/schemas/form";

const {
  formField: { ecommText },
} = checkout;

const validations = [
  Yup.object().shape({
    [ecommText.name]: Yup.string().required(ecommText.errorMsg),
  }),
];

export default validations;
