/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";

// Material Dashboard 2 React routes
import routes from "routes";
import FailedMarketsByRegion from "layouts/failedmarketsbyregion";
import FailedMarket from "layouts/failedmarket";
import EditFailedMarket from "layouts/editfailedmarket";
import EditFailedMarketZeroValue from "layouts/editfailedmarketzerovalue";
import EditFailedMarketPriceDataCheck from "layouts/editfailedmarketpricedatacheck";
import EditMarket from "layouts/editmarket";
import Market from "layouts/market";
import NewMarketRequest from "layouts/newmarketrequest";
import MarketFailedLedger from "layouts/dataprocessesdetail";
import MarketRequest from "layouts/marketrequest";
import MarketRequestManager from "layouts/marketrequestmanager";
import Widgets from "layouts/widgets";
import NewTag from "layouts/tags/new-tag";
import ViewTagsDetail from "layouts/viewtagsdetail";
import EditMarketRequest from "layouts/editmarketrequest";
import MarketRequestMarketTrading from "layouts/marketrequestmarkettradinginfo";
import UpdateMarketDataStoreDetail from "layouts/updatemarketdatastoredetail";
import UpdateMarketPriceDataDetail from "layouts/updatemarketpricedatadetail";
import CheckSocratesDataDetails from "layouts/checksocratesdatadetails";
import TagVolumeReport from "layouts/tagvolumereport";
import NullTags from "layouts/nulltags";
import NullTagsEdit from "layouts/nulltagsedit";
import NullCategories from "layouts/nullcategories";
import NullCategoriesEdit from "layouts/nullcategoriesedit";
import FailedMarketsZeroValueDetail from "layouts/failedmarketszerovaluedetail";
import BullishBearishDetail from "layouts/bullishbearishdetail";
import MarketPriceDataDetail from "layouts/marketpricedatadetail";
import NewMarketTalkText from "layouts/newmarkettalktext";
import EditMarketTalkText from "layouts/editmarkettalktext";
import ViewMarketTalkText from "layouts/viewmarkettalktext";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

import Cover from "layouts/authentication/reset-password/cover";
import Confirm from "layouts/authentication/reset-password/confirm";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="S O C R A T E S"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {configsButton}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route path="/failed-markets/region/:regionId" element={<FailedMarketsByRegion />} />
        <Route path="/failed-markets/:id/timeframe/:timeframeId" element={<FailedMarket />} />
        <Route
          path="/failed-markets/edit/:marketId/timeframe/:timeframeId"
          element={<EditFailedMarket />}
        />
        <Route
          path="/failed-markets/edit-zero-value/:marketId/timeframe/:timeframeId"
          element={<EditFailedMarketZeroValue />}
        />
        <Route
          path="/market-price-data-check/edit-market-price-data-check/:marketId/timeframe/:timeframeId"
          element={<EditFailedMarketPriceDataCheck />}
        />
        <Route
          path="/failed-markets-zero-value/:peiticker"
          element={<FailedMarketsZeroValueDetail />}
        />
        <Route path="/markets/:marketId" element={<Market />} />
        <Route path="/market-data-check/:PEITicker" element={<CheckSocratesDataDetails />} />
        <Route
          path="/market-data-check/:PEITicker/:FilterType/:TimeFrame"
          element={<CheckSocratesDataDetails />}
        />

        <Route path="/tag-volume-report/:TagId" element={<TagVolumeReport />} />
        <Route path="/processes/:region" element={<MarketFailedLedger />} />
        <Route path="/edit/:marketId" element={<EditMarket />} />
        <Route path="/reset-password" element={<Cover />} />
        <Route path="/reset-password/:userId" element={<Confirm />} />
        <Route path="/Market-Management/Submit-New-Market-Request" element={<NewMarketRequest />} />
        <Route path="/Market-Management/Edit-Market-Request" element={<EditMarketRequest />} />
        <Route path="/Market-Management/View-Market-Request" element={<MarketRequest />} />
        <Route path="/market-price-data-check/:id" element={<MarketPriceDataDetail />} />
        <Route
          path="/Market-Management/Edit-Market-Request-Market-Trading-Information"
          element={<MarketRequestMarketTrading />}
        />
        <Route
          path="/Market-Management/Market-Request-Manager"
          element={<MarketRequestManager />}
        />
        <Route
          path="/update-market-data-store/detail/:peiticker"
          element={<UpdateMarketDataStoreDetail />}
        />
        <Route
          path="/update-market-price-data/detail/:peiticker"
          element={<UpdateMarketPriceDataDetail />}
        />
        <Route path="/sockets" element={<Widgets />} />
        <Route path="/tags" element={<NewTag />} />
        <Route path="/view-tags-detail/:tagid" element={<ViewTagsDetail />} />
        <Route path="/markets-with-tag-as-null" element={<NullTags />} />
        <Route path="/markets-with-tag-as-null/edit-market" element={<NullTagsEdit />} />
        <Route path="/markets-with-category-as-null" element={<NullCategories />} />
        <Route path="/markets-with-category-as-null/edit-market" element={<NullCategoriesEdit />} />
        <Route path="/reversal-mapping/:peiticker" element={<BullishBearishDetail />} />
        <Route
          path="/markets-management/market-talk-text/new-market-talk-text"
          element={<NewMarketTalkText />}
        />
        <Route
          path="/markets-management/market-talk-text/edit-market-talk-text"
          element={<EditMarketTalkText />}
        />
        <Route
          path="/markets-management/market-talk-text/view-market-talk-text"
          element={<ViewMarketTalkText />}
        />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </ThemeProvider>
  );
}
