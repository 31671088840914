// react
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMaterialUIController, validateRole } from "context";
import { useQueries } from "@tanstack/react-query";

import http from "services/http";

import MDLoader from "components/MDLoader";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTableWithColumnFilters from "components/Tables/DataTableWithColumnFilters";

import percentageChangeTableData from "layouts/tables/data/percentageChangeTableData";

function PercentageChange() {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { userData } = controller;
  const { pathname } = useLocation();

  const [runRegionV, setRunRegionV] = useState("");
  const [timeframeV, setTimeframeV] = useState("");
  const [checkSocratesData, setCheckSocratesData] = useState([]);

  const { columns } = percentageChangeTableData();

  const getTimeframeValue = (timeframe) => {
    switch (timeframe) {
      case "Daily":
        return 1;
      case "Weekly":
        return 2;
      case "Monthly":
        return 3;
      case "Quarterly":
        return 4;
      case "Yearly":
        return 5;
      default:
        return 0;
    }
  };

  // const getRunRegionValue = (runregion) => {
  //   switch (runregion) {
  //     case "Americas":
  //       return ["US2", "US3"];
  //     case "Asia":
  //       return ["Asia"];
  //     case "Europe":
  //       return ["Europe"];
  //     case "Economics":
  //       return ["US4"];
  //     case "Bonds":
  //       return ["US1", "US5"];
  //     default:
  //       return [""];
  //   }
  // };

  const emptyResult = {
    1: [
      {
        result: [],
        TimeFrame: 1,
      },
    ],
    2: [
      {
        result: [],
        TimeFrame: 2,
      },
    ],
    3: [
      {
        result: [],
        TimeFrame: 3,
      },
    ],
    4: [
      {
        result: [],
        TimeFrame: 4,
      },
    ],
    5: [
      {
        result: [],
        TimeFrame: 5,
      },
    ],
  };

  const getPercentageChange = async () => {
    if (timeframeV === "" || runRegionV === "") return emptyResult;
    const result = await http({
      path: `/marketpricedata/${getTimeframeValue(timeframeV)}/${runRegionV}/history`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const [fetchedCheckSocratesData] = useQueries({
    queries: [
      {
        queryKey: ["fetchedPercentageChange", timeframeV, runRegionV],
        queryFn: getPercentageChange,
      },
    ],
  });

  useEffect(() => {
    if (timeframeV !== "" && runRegionV !== "" && fetchedCheckSocratesData.data) {
      const timeframe = getTimeframeValue(timeframeV);
      // const runregion = getRunRegionValue(runRegionV);

      // filters by Timeframe

      const { result: checksocratesdatafiltered } =
        fetchedCheckSocratesData.data[timeframe][0] || [];

      // filters RunRegion
      // const xchecksocratesdatafiltered = checksocratesdatafiltered[0].result.filter(
      //   (percentageChangeData) => runregion.includes(percentageChangeData.RunRegion)
      // );

      // Add timeframe to the data, which is used in Edit Column and Edit page

      const updatedData = checksocratesdatafiltered.map((element) => ({
        ...element,
        TimeFrame: timeframe,
      }));

      setCheckSocratesData(updatedData);
    }
  }, [runRegionV, timeframeV, fetchedCheckSocratesData.data]);

  useEffect(() => {
    if (userData !== null) {
      const { role } = userData;
      const { access, goBack } = validateRole({ role, pathname });
      if (!access) navigate(goBack);
    }
  }, [userData]);

  if (fetchedCheckSocratesData.isLoading) return <MDLoader />;
  if (fetchedCheckSocratesData.isError) return <div>Error at loading analysis market</div>;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3.5}>
              <Card>
                <Grid item xs={6} m={3} mb={2}>
                  <Autocomplete
                    id="RunRegion"
                    name="RunRegion"
                    options={["Americas", "Asia", "Europe", "Economics", "Bonds"]}
                    value={runRegionV || null}
                    // getOptionLabel={(option) => option}
                    style={{ width: 200 }}
                    onChange={(e, value) => {
                      setRunRegionV(value || "");
                    }}
                    renderInput={(params) => (
                      <MDInput {...params} variant="standard" label="Run Region" />
                    )}
                  />
                </Grid>
                <Grid item xs={6} m={3} mt={0} mb={4}>
                  <Autocomplete
                    id="TimeFrame"
                    name="TimeFrame"
                    options={["Daily", "Weekly", "Monthly", "Quarterly", "Yearly"]}
                    value={timeframeV || null}
                    // getOptionLabel={(option) => option}
                    style={{ width: 200 }}
                    onChange={(e, value) => {
                      // console.log(value);
                      setTimeframeV(value || "");
                    }}
                    renderInput={(params) => (
                      <MDInput {...params} variant="standard" label="Timeframe" />
                    )}
                  />
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      {checkSocratesData && checkSocratesData.length > 0 ? (
        <MDBox my={3} mt={5}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Percentage Change
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <DataTableWithColumnFilters
                    table={{ columns, rows: checkSocratesData }}
                    showTotalEntries
                    isSorted
                    noEndBorder
                    entriesPerPage
                    canSearch
                    arrayColumnsToSkipSorted={["Count"]}
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      ) : (
        <p> No data available</p>
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default PercentageChange;
