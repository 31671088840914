/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// Material Dashboard 2 React components
// import MDLink from "components/MDLink";
import { Link } from "react-router-dom";
import MDLink from "components/MDLink";
import MDTypography from "components/MDTypography";
import { NumberRangeColumnFilter } from "components/TableFilters/selectByField";
import { formatDateInNewYorkNoSec } from "utils/dateUtil";

export default function data() {
  return {
    columns: [
      {
        Header: "Name",
        accessor: "Name",
        align: "left",
        maxWidth: 250,
        minWidth: 250,
        Filter: "",
      },
      {
        Header: "PEITicker",
        accessor: "PEITicker",
        align: "left",
        maxWidth: 85,
        minWidth: 85,
        Filter: "",
      },
      {
        Header: "RunRegion",
        accessor: "RunRegion",
        align: "left",
        maxWidth: 75,
        minWidth: 75,
        Filter: "",
      },
      {
        Header: "Count",
        accessor: (d) =>
          d.changesNoZeroOpen.length +
          d.changesNoZeroHigh.length +
          d.changesNoZeroLow.length +
          d.changesNoZeroClose.length,
        align: "left",
        maxWidth: 140,
        minWidth: 140,
        Filter: NumberRangeColumnFilter,
        filter: "between",
        isSorted: false,
      },
      {
        Header: "Reported Date",
        accessor: (d) => {
          let max = d.changesNoZeroOpen[0]?.changeNoZeroOpen || 0;
          let obj = d.changesNoZeroOpen[0] || {};
          if (d.changesNoZeroHigh[0]?.changeNoZeroHigh > max) {
            max = d.changesNoZeroHigh[0].changeNoZeroHigh;
            // eslint-disable-next-line prefer-destructuring
            obj = d.changesNoZeroHigh[0];
          }
          if (d.changesNoZeroLow[0]?.changeNoZeroLow > max) {
            max = d.changesNoZeroLow[0].changeNoZeroLow;
            // eslint-disable-next-line prefer-destructuring
            obj = d.changesNoZeroLow[0];
          }
          if (d.changesNoZeroClose[0]?.changeNoZeroClose > max) {
            max = d.changesNoZeroClose[0].changeNoZeroClose;
            // eslint-disable-next-line prefer-destructuring
            obj = d.changesNoZeroClose[0];
          }

          return obj.Date;
        },
        align: "left",
        maxWidth: 160,
        minWidth: 160,
        Filter: "",
        Cell: ({ row }) => {
          let max = row.original.changesNoZeroOpen[0]?.changeNoZeroOpen || 0;
          let obj = row.original.changesNoZeroOpen[0] || {};
          if (row.original.changesNoZeroHigh[0]?.changeNoZeroHigh > max) {
            max = row.original.changesNoZeroHigh[0].changeNoZeroHigh;
            // eslint-disable-next-line prefer-destructuring
            obj = row.original.changesNoZeroHigh[0];
          }
          if (row.original.changesNoZeroLow[0]?.changeNoZeroLow > max) {
            max = row.original.changesNoZeroLow[0].changeNoZeroLow;
            // eslint-disable-next-line prefer-destructuring
            obj = row.original.changesNoZeroLow[0];
          }
          if (row.original.changesNoZeroClose[0]?.changeNoZeroClose > max) {
            max = row.original.changesNoZeroClose[0].changeNoZeroClose;
            // eslint-disable-next-line prefer-destructuring
            obj = row.original.changesNoZeroClose[0];
          }

          return formatDateInNewYorkNoSec(obj.Date);
        },
      },
      {
        Header: "Maximum Percentage Found",
        accessor: (d) =>
          Math.max(
            d.changesNoZeroOpen[0]?.changeNoZeroOpen || 0,
            d.changesNoZeroHigh[0]?.changeNoZeroHigh || 0,
            d.changesNoZeroLow[0]?.changeNoZeroLow || 0,
            d.changesNoZeroClose[0]?.changeNoZeroClose || 0
          ),
        align: "center",
        maxWidth: 210,
        minWidth: 210,
        Filter: "",
        Cell: ({ row }) => {
          let max = row.original.changesNoZeroOpen[0]?.changeNoZeroOpen || 0;
          // let obj = row.original.changesNoZeroOpen[0];
          if (row.original.changesNoZeroHigh[0]?.changeNoZeroHigh > max) {
            max = row.original.changesNoZeroHigh[0].changeNoZeroHigh;
            // eslint-disable-next-line prefer-destructuring
            // obj = row.original.changesNoZeroHigh[0];
          }
          if (row.original.changesNoZeroLow[0]?.changeNoZeroLow > max) {
            max = row.original.changesNoZeroLow[0].changeNoZeroLow;
            // eslint-disable-next-line prefer-destructuring
            // obj = row.original.changesNoZeroLow[0];
          }
          if (row.original.changesNoZeroClose[0]?.changeNoZeroClose > max) {
            max = row.original.changesNoZeroClose[0].changeNoZeroClose;
            // eslint-disable-next-line prefer-destructuring
            // obj = row.original.changesNoZeroClose[0];
          }

          return `${max.toFixed(2)}%`;
        },
      },
      {
        Header: "Found In",
        accessor: (d) =>
          Math.max(
            d.changesNoZeroOpen[0]?.changeNoZeroOpen || 0,
            d.changesNoZeroHigh[0]?.changeNoZeroHigh || 0,
            d.changesNoZeroLow[0]?.changeNoZeroLow || 0,
            d.changesNoZeroClose[0]?.changeNoZeroClose || 0
          ),
        align: "center",
        maxWidth: 55,
        minWidth: 55,
        Filter: "",
        Cell: ({ row }) => {
          let max = row.original.changesNoZeroOpen[0]?.changeNoZeroOpen || 0;
          let property = "Open";
          // let obj = row.original.changesNoZeroOpen[0];
          if (row.original.changesNoZeroHigh[0]?.changeNoZeroHigh > max) {
            max = row.original.changesNoZeroHigh[0].changeNoZeroHigh;
            property = "High";
            // eslint-disable-next-line prefer-destructuring
            // obj = row.original.changesNoZeroHigh[0];
          }
          if (row.original.changesNoZeroLow[0]?.changeNoZeroLow > max) {
            max = row.original.changesNoZeroLow[0].changeNoZeroLow;
            property = "Low";
            // eslint-disable-next-line prefer-destructuring
            // obj = row.original.changesNoZeroLow[0];
          }
          if (row.original.changesNoZeroClose[0]?.changeNoZeroClose > max) {
            max = row.original.changesNoZeroClose[0].changeNoZeroClose;
            property = "Close";
            // eslint-disable-next-line prefer-destructuring
            // obj = row.original.changesNoZeroClose[0];
          }

          return property;
        },
      },
      {
        Header: "Repeated Close Value",
        accessor: (d) =>
          d.eqPrevOpen.length + d.eqPrevHigh.length + d.eqPrevLow.length + d.eqPrevClose.length,
        align: "center",
        maxWidth: 155,
        minWidth: 155,
        Filter: "",
        Cell: ({ row }) => {
          const url = `/market-price-data-check/${row.original.PEITicker}`;
          const totalRecords =
            row.original.eqPrevOpen.length +
            row.original.eqPrevHigh.length +
            row.original.eqPrevLow.length +
            row.original.eqPrevClose.length;
          if (
            row.original.eqPrevOpen.length +
              row.original.eqPrevHigh.length +
              row.original.eqPrevLow.length +
              row.original.eqPrevClose.length >
            0
          ) {
            return (
              <Link to={url} state={{ percentageChangeInfo: row.original }}>
                <MDTypography
                  component="span"
                  variant="h7"
                  color="info"
                  fontWeight="medium"
                  sx={{ lineHeight: 0 }}
                >
                  {totalRecords}
                </MDTypography>
              </Link>
            );
          }
          return (
            <MDTypography
              component="span"
              variant="h7"
              color="info"
              fontWeight="medium"
              sx={{ lineHeight: 0 }}
            >
              {totalRecords}
            </MDTypography>
          );
        },
      },
      {
        Header: "Missing Data Points",
        accessor: (d) => d.dateDiff2.length,
        align: "center",
        maxWidth: 70,
        minWidth: 70,
        Filter: "",
        // Filter: NumberRangeColumnFilter,
        // filter: "between",
        // isSorted: false,
        Cell: ({ row }) => {
          const url = `/market-price-data-check/${row.original.PEITicker}`;
          const totalRecords = row.original.dateDiff2.length;
          if (row.original.dateDiff2.length > 0) {
            return (
              <Link to={url} state={{ percentageChangeInfo: row.original }}>
                <MDTypography
                  component="span"
                  variant="h7"
                  color="info"
                  fontWeight="medium"
                  sx={{ lineHeight: 0 }}
                >
                  {totalRecords}
                </MDTypography>
              </Link>
            );
          }
          return (
            <MDTypography
              component="span"
              variant="h7"
              color="info"
              fontWeight="medium"
              sx={{ lineHeight: 0 }}
            >
              {totalRecords}
            </MDTypography>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "Edit",
        Filter: "",
        align: "center",
        maxWidth: 50,
        minWidth: 50,
        Cell: ({ row }) => (
          <MDLink
            to={`/market-price-data-check/edit-market-price-data-check/${row.original.PEITicker}/timeframe/${row.original.TimeFrame}`}
            text="Edit"
          />
        ),
      },
    ],
  };
}
