/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { formatDateInNewYorkNoSec } from "utils/dateUtil";

function CommentsList({ title, comments, shadow }) {
  const renderProfiles = comments
    .sort((a, b) => b.PostedON - a.PostedON)
    .map(({ _id, Name, Summary, PostedON }) => (
      <MDBox key={_id} component="li" display="flex" alignItems="center" py={1} mb={1}>
        <MDBox
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          <MDTypography variant="caption" fontWeight="bold">
            {Summary}
          </MDTypography>
          <MDTypography variant="caption" fontWeight="light">
            {Name}
          </MDTypography>
        </MDBox>
        <MDBox ml="auto">
          <MDTypography variant="caption" fontWeight="medium">
            {formatDateInNewYorkNoSec(PostedON)}
          </MDTypography>
        </MDBox>
      </MDBox>
    ));

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderProfiles}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the CommentsList
CommentsList.defaultProps = {
  shadow: true,
};

// Typechecking props for the CommentsList
CommentsList.propTypes = {
  title: PropTypes.string.isRequired,
  comments: PropTypes.arrayOf(PropTypes.object).isRequired,
  shadow: PropTypes.bool,
};

export default CommentsList;
