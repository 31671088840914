import { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import { Grid, Card } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewMarketRequest page components
import UserInfo from "layouts/viewmarkettalktext/components/UserInfo";

// NewMarketRequest layout schemas for form and form feilds
import validations from "layouts/newmarkettalktext/schemas/validations";
import form from "layouts/newmarkettalktext/schemas/form";
import initialValues from "layouts/newmarkettalktext/schemas/initialValues";
import { useNavigate, useLocation } from "react-router-dom";

function getStepContent(formData) {
  return <UserInfo formData={formData} />;
}

function ViewMarketTalkText() {
  const location = useLocation();
  const { row } = location.state;

  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];

  const submitForm = async (values, actions) => {
    // eslint-disable-next-line no-unused-vars
    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
    navigate("/markets-management/market-talk-text");
  };

  const handleBack = (e) => {
    e.preventDefault();
    navigate("/markets-management/market-talk-text");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={0} mb={20} height="65vh">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, setFieldValue }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h6" color="white">
                        View Market Talk Text
                      </MDTypography>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent({
                          values,
                          touched,
                          formField,
                          errors,
                          setFieldValue,
                          row,
                        })}
                        <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          <MDButton variant="gradient" color="info" onClick={handleBack}>
                            back
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ViewMarketTalkText;
